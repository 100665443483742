.Alpha {
  text-align: center;
}

.Alpha-logo {
  height: 40vmin;
  pointer-events: none;
}

.Alpha-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(50px + 2vmin);
  font-weight: 900;
  color: #000000;
}

.Alpha-link {
  color: #000000;
  font-size: calc(8px + 2vmin);
  font-weight: normal;
  text-decoration: none;
}